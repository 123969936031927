export const getFiltered = (searchInput, data, filterColumns, isUserPage = false) => {
    let filteredData = data.filter(value => {
        return check(value, filterColumns, searchInput, isUserPage)
    });
    return filteredData
}

const check = (dt, clm, searchInput, isUserPage) => {
    let chk = false
    clm.forEach(function (name, i) {
        let dbValue = ''
        if (name.includes('.')) {
            let indexes = name.split(".");
            let getVal = isUserPage ? dt : dt['attributes']
            for (var i = 0; i < indexes.length; i++) {
                getVal = getVal[indexes[i]] ? getVal[indexes[i]] : ''
            }
            dbValue = getVal
        } else {
            if(isUserPage || name == 'id') {
                dbValue = dt[name] ? dt[name] : ''
            } else {
                dbValue = dt['attributes'][name] ? dt['attributes'][name] : ''
            }
        }

        if (dbValue && dbValue.toString().toLowerCase().includes(searchInput.toString().toLowerCase())) {
            chk = true;
            return
        }
    })
    return chk
}