import { Card, CardBody } from '@paljs/ui/Card';
import { InputGroup } from '@paljs/ui/Input';
import { Button } from '@paljs/ui/Button';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import '../../styles/common.css';
import {
  requireCheck,
  resetRequired,
  baseUri,
  showLoader,
  hideLoader,
  numFormatter,
  checkNumber,
  authCheck,
  ADMIN,
  getPageAccessFromState,
} from '../../utils/utils';
import { getFiltered } from '../../utils/table';
import DataTable from 'react-data-table-component';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const ActionBtn = styled(Button)`
  padding: 4px;
  margin-bottom: 10px;
`;

const InputPage = () => {
  const [groupData, setGroupData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableFilter, setTableFilter] = useState('');
  const state = useContext(GlobalStateData);
  const pageAccess = getPageAccessFromState(state);
  const [isSave, setSave] = useState(pageAccess.write === 1);
  useEffect(() => {
    authCheck(state.authData);
    getApi();
  }, []);

  const columns = [
    {
      name: 'Action',
      cell: (row) => (
        <div>
          <ActionBtn
            data-tag="allowRowEvents"
            className="abtn-color"
            onClick={() => {
              editGroup(row.id);
            }}
          >
            {' '}
            <EvaIcon name="edit" />{' '}
          </ActionBtn>
          {pageAccess.delete === 1 && (
            <ActionBtn
              data-tag="allowRowEvents"
              className="abtndel-color"
              onClick={() => {
                deleteGroup(row.id);
              }}
            >
              {' '}
              <EvaIcon name="trash" />{' '}
            </ActionBtn>
          )}
        </div>
      ),
    },
    {
      name: 'Groups',
      selector: (row) => `${row.attributes.groupCode} ${row.attributes.description}`,
      sortable: true,
      minWidth: '70%',
    },
  ];
  const filterColumns = ['groupCode', 'description'];

  const filterChange = (searchInput, newData = false) => {
    setTableFilter(searchInput);
    let available = [];
    if (newData) {
      available = newData;
    } else {
      available = groupData;
    }
    if (searchInput) {
      let filteredData = getFiltered(searchInput, available, filterColumns);
      setTableData(filteredData);
    } else {
      setTableData(available);
    }
  };

  const getApi = () => {
    showLoader();
    axios
      .get(baseUri() + 'groups?sort[0]=id:desc', {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setGroupData(response.data.data);
        if (tableFilter) {
          filterChange(tableFilter, response.data.data);
        } else {
          setTableData(response.data.data);
        }
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const [groupCode, setGroupCode] = useState('');
  const [description, setDescription] = useState('');
  const [pickId, setPickId] = useState('');

  const handleChange = (event) => {
    resetRequired(event);
    switch (event.target.name) {
      case 'groupCode':
        setGroupCode(event.target.value);
        break;
      case 'description':
        setDescription(event.target.value);
        break;
    }
  };

  const clearFormData = () => {
    setSave(pageAccess.write === 1);
    setGroupCode('');
    setDescription('');
    setPickId('');
    resetRequired();
  };

  const postApi = () => {
    const postData = {
      data: {
        groupCode,
        description,
      },
    };
    let validate = requireCheck(postData, groupData, 'groupCode', pickId);
    if (!validate) {
      return false;
    }
    showLoader();
    if (pickId) {
      axios
        .put(baseUri() + 'groups' + '/' + pickId, postData, {
          headers: {
            authorization: 'Bearer ' + state.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
        })
        .catch((error) => {
          //return  error;
        });
    } else {
      axios
        .post(baseUri() + 'groups', postData, {
          headers: {
            authorization: 'Bearer ' + state.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
        })
        .catch((error) => {
          //return  error;
        });
    }
  };

  const editGroup = (id) => {
    showLoader();
    clearFormData();
    axios
      .get(baseUri() + 'groups/' + id, {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setSave(pageAccess.edit === 1);
        setGroupCode(response.data.data.attributes.groupCode);
        setDescription(response.data.data.attributes.description);
        setPickId(response.data.data.id);
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const deleteGroup = (id) => {
    const removeGroup = window.confirm('Would you like to delete this group?');
    if (removeGroup) {
      showLoader();
      setPickId(id);
      axios
        .delete(baseUri() + 'groups/' + id, {
          headers: {
            authorization: 'Bearer ' + state.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
        })
        .catch((error) => {
          //return  error;
        });
    }
  };
  return (
    <>
      <SEO title="Group Code Master" />
      <Row>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header>Group Code Master</header>
            <CardBody>
              <label>
                Group Code <span className="red">*</span>
              </label>
              <Input fullWidth size="Small">
                <input
                  type="text"
                  onChange={(e) => handleChange(e)}
                  placeholder="Group Code *"
                  name="groupCode"
                  value={groupCode}
                  className="groupCode require"
                />
              </Input>
              <label>
                Description <span className="red">*</span>
              </label>
              <Input fullWidth>
                <textarea
                  rows={5}
                  onChange={(e) => handleChange(e)}
                  placeholder="Description *"
                  name="description"
                  value={description}
                  className="description require"
                />
              </Input>
              <Button
                status="Success"
                type="button"
                disabled={!isSave}
                className="btnrad"
                shape=""
                halfWidth
                onClick={postApi}
              >
                Save
              </Button>
              &nbsp; &nbsp;
              <Button status="danger" type="button" className="btnrad" shape="" halfWidth onClick={clearFormData}>
                Reset
              </Button>
            </CardBody>
          </Card>
        </Col>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header>Group Code Master</header>
            <CardBody>
              <Input fullWidth size="Small">
                <input
                  type="text"
                  onChange={(e) => filterChange(e.target.value)}
                  placeholder="Filter"
                  name="tableFilter"
                  value={tableFilter}
                />
              </Input>
              <DataTable
                columns={columns}
                data={tableData}
                fixedHeader
                allowOverflow={false}
                overflowY={false}
                overflowX={false}
                wrap={false}
                highlightOnHover={true}
                pagination={true}
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default InputPage;
